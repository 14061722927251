$com-primary: #1767c4;

$error-color: #AB2C52;
$error-color-light: #c5466c;
$font-color: #3E396B;
$font-light-color: #a1a6bf;
$placeholder-color:#c5c8d9;
$font-body: "Inter Regular", arial, sans-serif;
$font-heading: "Inter Semibold", arial, sans-serif;
$font-signature: "Learning Curve Regular", arial, sans-serif;
$nav-item-color:#1767c4;
$input-focus-color: #3167C0;
$checkbox-bg: #0c65c7;
$action-color: #1767c4;
$wizard-nav-step-color: rgba(23,103,196,.1);
$secondary-cta-bg-hover: #1c7ff0;
$content-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.15);
$line-item-block-bg: #FBFCFE;
$breadcrumb-bg: rgba(244, 247, 252, .8);
$complete-background-color: #33DC97;

$readonly-input-bg-color: #ebeff9;
$input-border-color: #c0c5d1;
$currency-icon-bg: rgba(192, 197, 209, .4);
$secondary-question-note: #9EA3B2;
$muted-color: #c5c8d9;
$muted-color-border:rgba(197, 200, 217, .3);
$light-body-color: #7e828e;
$floating-label-color: #7b85ad;
$border-color: rgba(62, 57, 107, .1);

$filed-color: #33DC97;
$needs-filing-color: #D23464;
$needs-filing-color-hover: #AB2C52;
$warning-color: #ecbb5f;

$form-error-color: #fff1f5;

$button-color-dark: #514B7A;
$button-color-light: #C4C6DF;
$button-action-bg: rgba(23,103,196,.1);

$table-zebra-dark: rgba(248, 250, 255, .7);

// Admin panel specific
$admin-link-color: #0074FF;
$admin-border-color: #ECEDEF;
$admin-bg-color: #F2F2F2;
$admin-icon-color: #B1AFC1;
$admin-icon-bg-color: #EFEEF2;
$admin-confirmation-btn: #18BE75;
$admin-confirmation-btn-hover: #1cce81;
$admin-confirm-bg-color: #E3F4EC;
$admin-confirm-font-color: #399876;
$admin-deleted-color: #D23364;
$admin-deleted-bg-color: #fff1f5;