/* Global responsive styles */
@media (min-width: 768px) {
    .container {
        max-width: 100% !important;
    }
}

@media only screen and (min-width: 1380px) {
    body {
        background-size: 100% !important;
    }
}

@media only screen and (min-width: 1575px) {
    .instructions h3 {
        text-shadow: 0 2px 5px rgba(0, 0, 0, .5);
        color: #FFF !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 450px) {

    .loading-wrapper {
        margin: 20px 0 !important;
    }

    .search-autocomplete .mat-option {
        line-height:initial !important;
        height: initial !important; 
        overflow: initial !important;
        white-space: initial !important;
        padding: 10px !important;
     }
    
    .loading-wrapper-cell {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0;
        margin-bottom: 20px;
        

        .text {
            margin-bottom: 10px;
            margin-right: 0;
        }

        .image, .button {
            display: none;
        }

        .text-line {
            width: 300px;
        }
    }

    th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
        padding: 10px 0 10px 10px !important;
    }


    .mde-popover-content {
        width: 80% !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .organizations-table {
        .mat-checkbox-layout {
            display: block;
            text-align: center;
        }

        th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
            padding-right: 0 !important;
        }
    }

    .mat-paginator-outer-container .mat-paginator-page-size-label {
        width: initial !important;
    }


    body {
        background-size: 90% !important;
        background-position: left center !important;
    }

    /**
     *  Header
     */
    .header-inner {
        width: 100% !important;


        .logo img {
            width: 130px !important;
        }
    }


    /** ===========================================================
     *  Homepage
        =========================================================== */
    .heading {
        width: calc(100% - 80px) !important;
        margin: 25px auto 10px auto !important;

        h1 {
            font-size: 22px !important;

            span {
                font-size: 16px !important;
            }
        }
    }

    .search-readonly.home {
        height: 90px !important;
        padding: 0 30px !important;
        font-size: 18px !important;
        box-shadow: inset 0 -4px 0px 1px #F6F7F9, 0 22px 30px rgba(0, 0, 0, .2) !important;
        padding: 0 30px !important;

        h2 {
            font-size: 18px !important;
            margin-top: 27px !important;
            margin-right: 20px !important;
            margin-bottom: 3px !important;
        }

        .search {
            right: 20px !important;
            top: 25px !important;

            img {
                width: 22px !important;
            }
        }
    }

    .filing-cards {
        width: calc(100% - 25px) !important;
        flex-direction: column !important;

        li {
            margin-right: 0 !important;
            margin-bottom: 25px !important;

            & > a, & > div, & > button {
                height: 75px !important;
            }

            & > button {
                height: 120px !important;

                &.needs-filing .status {
                    margin: 0 auto !important;
                    font-size: 14px !important;
                    padding: 4px 15px !important;
                }

                .file-by {
                    margin-bottom: 5px;
                }
            }

            .year {
                font-size: 30px !important;
                margin-bottom: 10px;
            }
        }
    }

    .dropdown-menu {

        .dropdown-item.active, .dropdown-item:active {
            .title {
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .dropdown-item {
            padding-left: 8px !important;
            padding-right: 8px !important;

            .title {
                text-overflow: ellipsis;
                font-size: 13px !important;
                overflow: hidden;
            }

            .ein {
                font-size: 13px !important;
            }
        }
    }

    .search-block.home, .search-readonly.home {
        width: calc(100% - 10px) !important;
        margin-top: 30px !important;
        margin: 30px auto;

        .ein {
            font-size: 16px !important;
        }

        input {
            height: 90px !important;
            padding: 0 60px 0 30px !important;
            width: 100% !important;
            font-size: 18px !important;
            box-shadow: inset 0 -4px 0px 1px #F6F7F9, 0 22px 30px rgba(0, 0, 0, .2) !important;
        }

        img {
            width: 30px !important;
            right: 20px !important;
            top: 30px !important;

            &.searching {
                top: 50% !important;
                margin-top: initial !important;
                @include transform(translateY(-50%));
            }
        }
    }

    /**
     *  Global
     */
    .content {
        width: calc(100% - 30px) !important;
        margin: 0 auto;

        .main {
            padding: 25px !important;

            // FAQ
            .faq-list {
                & > li {
                    margin: 0 0 10px 0 !important;
                    flex-basis: 100% !important;

                    &:last-child {
                        margin-bottom: 0 !important;
                    }
                }
            }

        }

        & > .top {
            margin: 20px 0 !important;

            h1 {
                font-size: 21px !important;
            }
        }
    }
}
