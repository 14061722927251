@use '@angular/material' as mat;
@include mat.core();

$com-primary: mat.define-palette(mat.$indigo-palette);
$com-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$com-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$com-theme: mat.define-light-theme($com-primary, $com-accent, $com-warn);

@include mat.all-component-themes($com-theme);

@import '@angular/material/prebuilt-themes/deeppurple-amber';
@import '@angular/cdk/overlay-prebuilt.css';

// Toaster styles
@import "../node_modules/angular2-toaster/toaster";

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@import "assets/scss/variables";
@import "assets/scss/buttons";
@import "assets/scss/fonts";
@import "assets/scss/font-inter";
@import "assets/scss/responsive";

body {
    background-color: #f8faff;
    font-family: $font-body;
    color: $font-color;
}

ul {
    list-style-type:none;
    padding:0;
}

.bold {
    font-family: $font-heading
}

.underline {
    text-decoration: underline;
}

p {
    font-family: $font-body;
}

/* Tablet format and lower */
@media only screen and (max-width: 1024px) {

}

/* Mobile format and lower */
@media only screen and (max-width: 768px) {

}

/* Mobile format landscape (wide) */
@media only screen and (min-width: 320px) and (max-width: 480px) {

}

/* Mobile format portrait (small) */
@media only screen and (max-width: 320px) {

}

html, body {
    height: 100%;
}

.loading {
    padding: 50px !important;
    width: 150px  !important;
    margin: 0 auto  !important;

}

a, a:hover {
    font-family: $font-body;
    color: $com-primary;
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.warning {
    color: $warning-color;
}

.error {
    color: $needs-filing-color;
}

.mat-checkbox-layout .mat-checkbox-label {
    font-weight: normal;
}

.loading-wrapper {
    margin: 13px 0;
    background: #fff;
    display: flex;
    flex-direction: column;
}

.loading-wrapper-cell {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.margin-up-down-20 {
    margin-top:20px !important;
    margin-bottom: 20px !important;
}

  // Animation

.animated-background {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #F6F6F6;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 800px 104px;
    height: 96px;
    position: relative;
}

.loading-wrapper {

    // Page Elements
    .image {
        height: 17px;
        width: 17px;
        margin-right: 50px;
        @extend .animated-background;
    }

    .button {
        margin-left: auto;
        width: 100px;
        height: 30px;
        @extend .animated-background;
    }

    .text {
        margin-right: 50px
    }

    .text-line {
        height: 16px;
        width: 400px;

        &:first-child {
            margin-bottom: 13px;
        }

    @extend .animated-background;
    }
}


/**
 *  Table loading data
 */
.mat-table {
    box-shadow: none;
    font-family: $font-body;

    tr.mat-header-row {
        height: initial;
    }

    th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
        padding: 15px;
    }

    th {
        font-family:$font-body;
        font-size: 14px;
        text-align: left;
        padding: 0 0 10px 0;
        color: $font-color;

        &.mat-header-cell:first-of-type {

        }
    }

    .no-results {
        margin: 50px 0;
        font-family: $font-body;
        color: $light-body-color;
    }
}

.mat-table + .loading-wrapper {
    border-radius: 4px;
    border:1px solid  $admin-border-color;
    padding: 30px 30px 10px 30px;
}

.caution {
    font-size: 13px;
    line-height: 150%;
    color: $font-light-color;
}

.general-text-button {
    
    span, mat-icon {
        @include transition(.15 ease-in-out);
    }
    
    &:hover {
        color: $com-primary;

        mat-icon {
            color: $com-primary !important;
        }
    }
}


/**
 *  Material styles
*/
.mat-toolbar {
    background-color: #f8faff;
}

.mat-calendar-body-selected {
    background-color: $nav-item-color;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical):active {
    background-color: rgba(49, 103, 192, .3) !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(49, 103, 192, .3) !important;
}

.mat-calendar-body-in-range::before {
    background-color: rgba(49, 103, 192, .2) !important;
}

mat-toolbar {
    .list-spacer {
        flex: 1 1 auto;
    }
}

.filing-instructions-container {
    margin-bottom: 20px;

    a {
        fa-icon {
            margin-right: 5px;
        }
    }
}

.wizard-mobile-nav {
    mat-option {
        position: relative;
    }

    .errors {
        border-radius: 20px;
        background: $error-color;
        position: absolute;
        right: 10px;
        top: 12px;
        color: #FFF;
        margin-left: 5px;
        font-size: 11px;
        font-family: $font-heading;
        height: 8px;
        width: 8px;
    }
}

.mat-error {
    font-size: 12px;
    color: $error-color;
    font-family: $font-body;

    // strong {
    //     font-family: $font-heading;
    // }
}

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
    color: $error-color;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
    border-width: 1px !important;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: $com-primary;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
    background-color: $error-color;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
    caret-color: $error-color;
}

.mat-menu-content {
    .mat-menu-item {
        font-family: $font-heading !important;
        color: $font-color !important;
        outline: 0;
        text-transform:uppercase;
        letter-spacing:1px;
        font-size:12px;
        padding: 0 16px;

        &.active {
            text-decoration:none;
            color: $nav-item-color !important;
        }
    }
}

.organizations-table {
    th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
        padding-left: 10px;
    }
}

// This handles the autocomplete popup
.cdk-overlay-pane {
    border-radius: 15px;
    overflow:hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2); // Uncomment in order to add this to add box shadow to dialogs and tooltips

    &.mat-tooltip-panel-above {
        box-shadow: none;
    }
}

.search-autocomplete .mat-option {
    &:hover {
        background: $readonly-input-bg-color;
    }
}

.mat-option-text {
    position: relative;
}

.autocalculated {

    &.plural:after {
        content: 'Values come directly from your answers later in the form.';
        font-style: italic;
        font-weight: normal;
        font-family: $font-body;
        color: $secondary-question-note;
    }

    &:after {
        content: 'This value comes directly from your answers later in the form.';
        font-style: italic;
        font-weight: normal;
        font-family: $font-body;
        color: $secondary-question-note;
    }
}


.mat-paginator-outer-container {
    font-family: $font-body;
    color: $font-color;
    opacity:.5;

    .mat-paginator-page-size-label {
        width: 150px;
    }
}

.line-item {
    mat-checkbox {
        .mat-checkbox-inner-container {
            margin-right: 15px !important;
        }
    }
}

.mde-popover-content {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
    padding: 20px !important;
}

.search-autocomplete {
    .mat-option {
        font-family: $font-body !important;
        color: $font-color;

        .mat-option-text {
            .org-name {
                font-family: $font-heading;
            }

            .org-ein {
                opacity: .6;
            }
        }
    }
}

.mat-spinner circle {
    stroke: $nav-item-color !important;
}

.mat-dialog-title, p {
    color: $font-color;
}

.mat-dialog-actions {
    .mat-button {
        line-height: initial !important;
        height: 40px;
    }
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background: $checkbox-bg;
}

.mat-checkbox-layout .mat-checkbox-label,
.mat-radio-label-content {
    font-family: $font-body;
    color: $font-color !important;
    white-space: normal;
    font-size: 13px;
    font-weight: normal;
}

.mat-select-value {
    font-family: $font-body;
}

.mat-dialog-title, .mat-button {
    font-family: $font-heading;
}

mat-radio-button {
    margin-right: 10px;
}

.amount-block .mat-form-field-infix {
    padding: 0 !important;
    width: 135px;
}

.question-set .amount-block .mat-form-field-infix {
    padding: 0 !important;
    width: 180px;
}

@keyframes pulse {
    0% {
      -webkit-transform:scale(0);
      opacity:0;
    }
    25% {
      -webkit-transform:scale(0.3);
      opacity:1;
    }
   50% {
      -webkit-transform:scale(0.6);
      opacity:.6;
    }
   75% {
      -webkit-transform:scale(.9);
      opacity:.3;
    }
   100% {
      -webkit-transform:scale(1);
      opacity:0;
    }
 }

.select-input {

    // mat-select {
    //     border: 1px solid $input-border-color;
    //     padding: 10px;
    //     border-radius: 25px;
    //     margin-bottom: 11px;
    //     margin-top: 10px;
    // }

    // // SPECIFIC TO SELECT INPUTS
    // // Removes the underline that shows up because of the mat input
    // .mat-form-field-underline {
    //     display: none;
    // }
}

.question-number {
    font-family: $font-heading;
    background: rgb(62 57 107 / 80%);
    color: #FFF;
    border-radius: 4px;
    padding: 2px 2px 0px 2px;
    min-width: 30px;
    text-align: center;
    display: inline-block;
    margin-right: 3px;

    &.info-item {
        background: rgb(62 107 57 / 80%);
    }
}

.table .col {
    app-address-form {
        .mat-select {
            padding-bottom: 18px !important;
            margin-top: 5px;
        }

        mat-grid-tile {
            overflow:initial;
        }
    }
}

.mat-input-element,
.mat-radio-label {
    font-size: 13px !important;
}

.mat-checkbox .mat-ripple-element,
.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
     background-color:$checkbox-bg !important;
}


.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background: $checkbox-bg !important;
    border-radius: 3px !important;
}

.mat-checkbox-frame,
.mat-radio-outer-circle {
    border-color: $input-border-color;
}

.question-desc {
    line-height: 170%;
}

// Some fixes to the 990-EZ & 990 form fields
//.table .flex-grid .col input:read-only {
//    visibility: hidden;
//}
.line-item-block {

    app-address-form {
        mat-grid-tile {
            height: 70px !important;
        }
    }
    mat-select {
        // padding-bottom: 18px;
    }
}

.mat-radio-disabled {
    opacity: .3;
}


.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background: $com-primary !important;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
 .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background: $input-border-color !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $input-border-color !important;
}

.textarea .mat-form-field-underline {
    display: none;
}

textarea:focus {
    border: 1px solid $nav-item-color !important;
}

mat-form-field {
    width: 100%;
    font-family: $font-body;

    &.mat-form-field-should-float {
        .mat-form-field-label {
            font-size:16px !important;
        }
    }

    &.mat-focused {
        .mat-form-field-label {
            color: $input-focus-color !important;;
        }

        .mat-form-field-underline {
            background: $input-focus-color !important;
        }

        .mat-form-field-ripple {
            background-color: $input-focus-color !important;
        }
    }

    input, textarea {
        font-family: $font-body !important;
        font-size: 14px;
        color: $font-color !important;
    }

    .mat-form-field-underline {
        height: 2px !important;
        background: $input-border-color !important;

        strong {
            font-family: $font-body;
        }
    }

    .mat-form-field-invalid .mat-form-field-underline {
        height: 1px !important;
        background: none;
    }

    .mat-form-field-label {
        font-family: $font-heading;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $floating-label-color !important;

        font-size: 14px;
        @include transition(.15s ease-in-out);
    }

}

.mat-select-value-text, .mat-option-text {
    font-family: $font-body !important;
    font-size: 14px;
    color: $font-color !important;
}

.pointer {
    cursor: pointer;
}

.clear, .clearfix {
    clear: both;
}

.strikethrough {
    text-decoration: line-through !important;
}

.success {
    align-items: center;
    background: $filed-color;
    border-radius: 100px;
    display: flex;
    height: 25px;
    justify-content: center;
    width: 25px;

    img {
        width: 12px;
    }
}
