@import './mixins';

button {
    padding: 0;
    border: 0;
    background: none;
}

.blue-gradient {
    border-radius: 100px !important;
    font-size: 14px;
    cursor: pointer;
    font-family: $font-heading;
    padding: 7px 30px !important;
    background: rgb(31, 200, 220); /* Old browsers */
    background: -moz-linear-gradient(45deg, rgba(31, 200, 220, 1) 0%, rgba(32, 154, 239, 1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, rgba(31, 200, 220, 1) 0%, rgba(32, 154, 239, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, rgba(31, 200, 220, 1) 0%, rgba(32, 154, 239, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1fc8dc', endColorstr='#209aef', GradientType=1); /* IE6-9 fallback on horizontal gradient */
    color: #FFF !important;
    outline: 0;
    @include transition(.1s ease-in-out !important);

    img {
        margin-right: 5px;
    }

    &:disabled {
        background: $muted-color;
        cursor: default;

        &:hover {
            @include transform(scale(1));
        }
    }

    &:active {
        box-shadow: inset 0 2px 0 rgba(0, 0, 0, .1);
        @include transition(none);
    }

    &:hover {
        opacity: .8;
    }
}

.green-gradient {
    border-radius: 40px !important;
    font-size: 14px;
    cursor: pointer;
    outline: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
    border: 0;
    font-family: $font-heading !important;
    padding: 7px 30px !important;
    background: rgb(28, 226, 146); /* Old browsers */
    background: -moz-linear-gradient(45deg, rgba(28, 226, 146, 1) 0%, rgba(24, 188, 114, 1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, rgba(28, 226, 146, 1) 0%, rgba(24, 188, 114, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, rgba(28, 226, 146, 1) 0%, rgba(24, 188, 114, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1ce292', endColorstr='#18bc72', GradientType=1); /* IE6-9 fallback on horizontal gradient */
    color: #FFF !important;
    @include transition(.1s ease-in-out !important);

    img {
        margin-right: 10px;
    }

    mat-icon {
        margin-right: 5px;
    }

    &:disabled {
        background: $muted-color;
        cursor: default;

        &:hover {
            @include transform(scale(1));
        }
    }

    &:active {
        box-shadow: inset 0 2px 0 rgba(0, 0, 0, .1);
        @include transition(none);
    }

    &:hover {
        opacity: .8;
    }
}

.red-gradient {
    border-radius: 40px !important;
    font-size: 14px;
    cursor: pointer;
    outline: 0 !important;
    display: flex;
    justify-content: center;
    align-items: middle;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
    border: 0;
    font-family: $font-heading !important;
    padding: 7px 30px !important;
    background: rgb(226, 78, 78); /* Old browsers */
    background: -moz-linear-gradient(45deg, rgba(226, 78, 78, 1) 0%, rgba(186, 65, 65, 1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, rgba(226, 78, 78, 1) 0%, rgba(186, 65, 65, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, rgba(226, 78, 78, 1) 0%, rgba(186, 65, 65, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1ce292', endColorstr='#18bc72', GradientType=1); /* IE6-9 fallback on horizontal gradient */
    color: #FFF !important;
    @include transition(.1s ease-in-out !important);

    img {
        margin-right: 10px;
    }

    &:disabled {
        background: $muted-color;
        cursor: default;

        &:hover {
            @include transform(scale(1));
        }
    }

    &:active {
        box-shadow: inset 0 2px 0 rgba(0, 0, 0, .1);
        @include transition(none);
    }

    &:hover {
        opacity: .8;
    }
}

.purple-gradient {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2f51d8+0,617edd+100 */
    background: rgb(47, 81, 216); /* Old browsers */
    background: -moz-linear-gradient(45deg, rgba(47, 81, 216, 1) 0%, rgba(97, 126, 221, 1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, rgba(47, 81, 216, 1) 0%, rgba(97, 126, 221, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, rgba(47, 81, 216, 1) 0%, rgba(97, 126, 221, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2f51d8', endColorstr='#617edd', GradientType=1); /* IE6-9 fallback on horizontal gradient */
    border-radius: 100px !important;
    font-size: 14px;
    cursor: pointer;
    outline: 0 !important;
    display: block;
    text-decoration: none !important;
    border: 0;
    font-family: $font-heading !important;
    padding: 7px 30px !important;
    color: #FFF !important;
    @include transition(.1s ease-in-out !important);

    &:disabled {
        background: $muted-color;
        cursor: default;

        &:hover {
            @include transform(scale(1));
        }
    }

    &:active {
        box-shadow: inset 0 2px 0 rgba(0, 0, 0, .1);
        @include transition(none);
    }

    &:hover {
        opacity: .8;
    }
}

.action-text-button {
    align-items: center;
    border-radius: 3px;
    color: #666;
    cursor: pointer;
    display: flex;
    font-size: 13px;
    font-family: $font-heading;
    letter-spacing: 1px;
    outline: 0;
    padding: 7px 10px 3px 10px;
    text-transform: uppercase;
    @include transition(.15s ease-in-out);

    &:hover {
        color: #1767c4;
    }

    img, i {
        margin-right: 5px;
    }
}
