@mixin transition($transition) {
  -webkit-transition: $transition;
     -moz-transition: $transition;
      -ms-transition: $transition;
          transition: $transition;
}

@mixin transform($transform) {
  -webkit-transform: $transform;
     -moz-transform: $transform;
      -ms-transform: $transform;
          transform: $transform;
}